import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import "./App.css";
import * as Yup from "yup";

const fuelSchema = Yup.object().shape({
	priceA: Yup.number()
		.required("Camp obligatoriu")
		.positive("valaorea trebuie sa fie > 0")
		.moreThan(0, "pretul nu poate fi 0 !"),
	economyA: Yup.number()
		.required()
		.positive("valaorea trebuie sa fie > 0")
		.moreThan(0, "consumul nu poate fi 0 !"),
	priceB: Yup.number()
		.required("Camp obligatoriu")
		.positive("valaorea trebuie sa fie > 0")
		.moreThan(0, "pretul nu poate fi 0 !"),
});

interface IFormValues {
	priceA: number;
	economyA: number;
	priceB: number;
	economyB: number;
}

function App() {
	const [initialValues, setInitialValues] = useState<IFormValues>({
		priceA: 6.59,
		economyA: 10,
		priceB: 8,
		economyB: 12.14,
	});

	const handleSubmit = ({
		priceA,
		priceB,
		economyA,
		economyB,
	}: IFormValues) => {
		economyB = (economyA * priceB) / priceA;
		economyB = parseFloat(economyB.toFixed(2));

		setInitialValues((oldState: IFormValues) => ({
			...oldState,
			priceA,
			economyA,
			priceB,
			economyB,
		}));
	};
	return (
		<div className="App">
			<h2 style={{ textAlign: "center", marginBottom: "40px" }}>
				CALCULATOR ECHIVALENTA CONSUM
			</h2>
			<Formik
				onSubmit={handleSubmit}
				initialValues={initialValues}
				validationSchema={fuelSchema}
				enableReinitialize
			>
				{({ errors, touched }) => (
					<Form>
						<div className="parent-container">
							<section className="flex-vertical section">
								<label htmlFor="priceA">Pret combustibil A</label>
								<Field
									id="priceA"
									name="priceA"
									type="number"
									className="field"
								/>

								<label htmlFor="economyA">Consum masina A (L/100km)</label>
								<Field
									id="economyA"
									name="economyA"
									type="number"
									className="field"
								/>
							</section>
							<section className="flex-vertical section">
								<label htmlFor="priceB">Pret combustibil B</label>
								<Field
									id="priceB"
									name="priceB"
									type="number"
									className="field"
								/>

								<label htmlFor="economyB">Consum masina B (L/100km)</label>
								<Field
									id="economyB"
									name="economyB"
									type="number"
									className="field"
									disabled
									style={{
										border: "1px solid #4fb035",
										backgroundColor: "##cdedc4",
									}}
								/>
							</section>
						</div>
						<button className="submit" type="submit">
							CALCULEAZA !
						</button>
						{errors["priceA"] && touched["priceA"] && (
							<div className="errorValidation">{errors["priceA"]}</div>
						)}
						{errors["economyA"] && touched["economyA"] && (
							<div className="errorValidation">{errors["economyA"]}</div>
						)}
						{errors["priceB"] && touched["priceB"] && (
							<div className="errorValidation">{errors["priceB"]}</div>
						)}
					</Form>
				)}
			</Formik>
		</div>
	);
}

export default App;
